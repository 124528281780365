import React from 'react'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { PaymentDetails } from '@plastiq/react-components'

import { handlePostMessage } from '../../utils/helpers'
import { useNotification } from '../../notification/useNotification'
import BodyAnimation from '../../components/BodyAnimation'

import {
  createPaymentIntent,
  patchPaymentIntent,
} from '../paymentIntentReducer'
import { createDocument, uploadDocument } from '../../documents/reducer'
import eventService from '../../utils/event/EventService'
import { EVENT } from '../../utils/event/constants'

function Details() {
  const paymentIntent = useSelector(state => state.paymentIntent.selected)
  const { id: payerId } = useSelector(state => state.payer.selected)
  const { receivingMethod } = useSelector(state => state.recipient.selected)
  const { parentOrigin } = useSelector(state => state.app)
  const { state } = useLocation()
  const { displayNotification } = useNotification()
  const isStart = state?.prevRoute?.pathname === '/'
  const currency = receivingMethod?.type === 'EFT' ? 'CAD' : 'USD'
  const form = useForm({
    defaultValues: {
      amount: paymentIntent?.targetAmount?.value.toString(),
      accountNumber: paymentIntent?.details?.accountNumber,
      accountName: paymentIntent?.details?.accountName,
      memo: paymentIntent?.details?.memo,
      currency,
    },
  })

  const disableDocumentUpload = paymentIntent?.disableDocumentUpload === true

  const isReadOnlyView =
    paymentIntent?.readOnly &&
    paymentIntent?.targetAmount?.value.toString() !== undefined &&
    paymentIntent?.details?.accountNumber !== undefined &&
    paymentIntent?.details?.accountName !== undefined

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const handleOnSubmit = async data => {
    let newPaymentIntent, uploadedDocumentError
    if (paymentIntent?.id)
      newPaymentIntent = await dispatch(patchPaymentIntent(data))
    else newPaymentIntent = await dispatch(createPaymentIntent(data))
    const { error: paymentIntentError } = newPaymentIntent
    handlePostMessage(newPaymentIntent, parentOrigin, 'PAYMENT_INTENT')

    if (data.invoiceUploader?.length) {
      const newFile = data.invoiceUploader[0][0]
      const { type, name: filename } = newFile
      const fileType = type?.split('/')[1]
      const {
        payload: { id: paymentIntentId },
      } = newPaymentIntent
      const documentToCreate = {
        paymentIntent: { id: paymentIntentId },
        payer: { id: payerId },
        type: 'INVOICE',
        fileType,
        filename,
      }

      const newDocument = await dispatch(createDocument(documentToCreate))
      const { payload: documentPayload, error: documentError } = newDocument
      handlePostMessage(newDocument, parentOrigin, 'DOCUMENT')
      // TODO: render error message from redux store?
      if (documentError) {
        displayNotification({
          message: 'Error creating document',
          type: 'error',
        })
        return
      }

      const { uploadURL, metadata } = documentPayload
      const uploadedDocument = await dispatch(
        uploadDocument({ uploadURL, file: newFile, filename, ...metadata }),
      )

      uploadedDocumentError = uploadedDocument?.error
    }

    eventService.handle({
      eventName: EVENT.DETAILS_SUBMIT,
      eventProperties: {
        amount: data.amount,
        hasInvoice: Boolean(data.invoiceUploader?.length),
        hasPaymentIntentError: Boolean(paymentIntentError),
        hasUploadedDocError: Boolean(uploadedDocumentError),
      },
    })

    // TODO: render error message from redux store?
    if (uploadedDocumentError) {
      displayNotification({
        message: 'Error uploading document',
        type: 'error',
      })
      return
    }

    !paymentIntentError && navigate('/payment/review')
  }

  const handleSubmit = form.handleSubmit(handleOnSubmit)

  const handleClickBack = () => {
    handlePostMessage({}, parentOrigin, 'PAYMENT_INTENT', 'BACK')
    navigate(-1)
  }

  return (
    <BodyAnimation>
      <form onSubmit={handleSubmit}>
        <PaymentDetails
          isStart={isStart}
          form={form}
          onSubmit={handleSubmit}
          amount={{ required: true }}
          currency={currency}
          accountName={{ required: true }}
          memo={{
            value: paymentIntent?.memo?.value,
          }}
          disableDocumentUpload={disableDocumentUpload}
          onClickBack={handleClickBack}
          isReadOnlyView={isReadOnlyView}
        />
      </form>
    </BodyAnimation>
  )
}

export default Details
