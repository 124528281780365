import { useDispatch, useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import { useNavigate } from 'react-router-dom'
import { DeliveryMethod } from '@plastiq/react-components'

import { createRecipient } from '../reducer'
import { handlePostMessage, getRoutingNumberForEFT } from '../../utils/helpers'
import { useNotification } from '../../notification/useNotification'
import eventService from '../../utils/event/EventService'
import { EVENT } from '../../utils/event/constants'

function DeliveryInformation() {
  const dispatch = useDispatch()
  const recipient = useSelector(state => state?.recipient?.selected)
  const payer = useSelector(state => state?.payer?.selected)
  const navigate = useNavigate()
  const { parentOrigin, theme = {} } = useSelector(state => state.app)
  const { displayNotification } = useNotification()

  const form = useForm({
    defaultValues: recipient.receivingMethod,
  })

  const handleOnSubmit = async dataSubmitted => {
    const newRecipient = {
      payer: {
        id: payer.id,
      },
      ...recipient,
      receivingMethod: {
        ...dataSubmitted,
        routingNumber:
          dataSubmitted.routingNumber ||
          getRoutingNumberForEFT(
            dataSubmitted.transitNumber,
            dataSubmitted.institutionNumber,
          ),
      },
    }
    const response = await dispatch(createRecipient(newRecipient))
    handlePostMessage(response, parentOrigin, 'RECIPIENT')

    const { error } = response
    // TODO: render error message from redux store?
    if (error)
      displayNotification({
        message: 'Error creating recipient',
        type: 'error',
      })

    eventService.handle({
      eventName: EVENT.DELIVERY_METHOD_SUBMIT,
      eventProperties: {
        deliveryMethodType: newRecipient.receivingMethod.type,
        country: newRecipient.receivingMethod.country,
        hasError: Boolean(error),
      },
    })

    !error && navigate('/payment-method')
  }
  const handleSubmit = form.handleSubmit(handleOnSubmit)

  const handleClickBack = () => {
    navigate(-1)
  }

  return (
    <form onSubmit={handleSubmit}>
      <DeliveryMethod
        recipientName={recipient.businessName}
        country={{
          name: 'country',
          value: recipient.businessAddress.country,
        }}
        theme={theme}
        form={form}
        onClickBack={handleClickBack}
        onSubmit={handleSubmit}
      />
    </form>
  )
}

export default DeliveryInformation
